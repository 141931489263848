<template>
  <div class="infoPage">
    <div class="topImg">
<!--      <img :src="data.hotelCover" alt="">-->
<!--      <swiper :options="swiperOptionJD" class="swiperList">-->
<!--        <swiper-slide v-for="(item,index) in data.imgList" class="item-swiper">-->
<!--          <img :src="item" width="100%" height="100%" style="object-fit: cover;" alt="">-->
<!--        </swiper-slide>-->
<!--      </swiper>-->
    </div>
    <div class="content">
      <div class="navListBox">{{ titleStr }}> <span>#{{ data.hotelTitle }}</span> </div>
      <div class="infoBox">
        <div class="box">
          <div class="left">
            <div class="info">
              <div class="title">
                <div class="name">{{data.hotelTitle}}</div>
                <div class="start">
                  <span v-if="data.hotelLevel==1">★</span>
                  <span v-if="data.hotelLevel==2">★★</span>
                  <span v-if="data.hotelLevel==3">★★★</span>
                  <span v-if="data.hotelLevel==4">★★★★</span>
                  <span v-if="data.hotelLevel==5">★★★★★</span>
                </div>
              </div>
              <div class="tags">
                <!--              <div class="item">豪华酒店</div>-->
                <!--              <div class="item">游泳池</div>-->
              </div>
              <div class="brief">
                {{data.hotelDesc}}
              </div>
            </div>
            <div class="phone">
              <div class="title" v-if="tType == 'CH'">
                酒店电话
              </div>
              <div class="title" v-if="tType == 'EN'">
                Hotel phone
              </div>
              <div class="icon">
                <i class="el-icon-phone"></i>
                <span>{{ data.hotelContactPhone }}</span>
              </div>

            </div>
          </div>
          <!--        <div class="right">-->

          <!--        </div>-->
        </div>
        <div class="parse">
          <div class="title" v-if="tType == 'CH'">酒店介绍</div>
          <div class="title" v-if="tType == 'EN'">Hotel Introduction</div>
          <div class="html" v-html="data.hotelContent">
            <!--          无锡挚舍·南禅观水酒店位于无锡市清名桥历史街区，占地面积约1200多平方米。-->
            <!--          挚舍·南禅观水 执子之手， 与子偕老 ， 挚爱之心，守爱之舍，假如有一天，你从远方归来，你曾踩着春风上路，也在秋风中温习，你沧桑也稚嫩，那些美好的时光，总是一半珍藏，一半流失 。你放慢脚步，在人文美学的旅居空间，享受一个静静的清晨和黄昏，体现的是一种城市民宿文化，设计师认为民宿出采于细节，用心极其考究，每个房间都有自己的风格和故事。-->
            <!--          无锡挚舍·南禅观水酒店位于无锡市清名桥历史街区，占地面积约1200多平方米。-->
            <!--          挚舍·南禅观水 执子之手， 与子偕老 ， 挚爱之心，守爱之舍，假如有一天，你从远方归来，你曾踩着春风上路，也在秋风中温习，你沧桑也稚嫩，那些美好的时光，总是一半珍藏，一半流失 。你放慢脚步，在人文美学的旅居空间，享受一个静静的清晨和黄昏，体现的是一种城市民宿文化，设计师认为民宿出采于细节，用心极其考究，每个房间都有自己的风格和故事。-->
            <!--          无锡挚舍·南禅观水酒店位于无锡市清名桥历史街区，占地面积约1200多平方米。-->
            <!--          挚舍·南禅观水 执子之手， 与子偕老 ， 挚爱之心，守爱之舍，假如有一天，你从远方归来，你曾踩着春风上路，也在秋风中温习，你沧桑也稚嫩，那些美好的时光，总是一半珍藏，一半流失 。你放慢脚步，在人文美学的旅居空间，享受一个静静的清晨和黄昏，体现的是一种城市民宿文化，设计师认为民宿出采于细节，用心极其考究，每个房间都有自己的风格和故事。-->
            <!--          <img src="../../../assets/img/jiudian.png" alt="">-->
          </div>
        </div>
      </div>


    </div>
    <div class="Dynamic">
      <div class="title">
        <div class="text" v-if="tType == 'CH'">
          其他推荐
        </div>
        <div class="text" v-if="tType == 'EN'">
          Other recommended
        </div>
        <div class="lookAll">
          <!--            查看更多-->
<!--          <span class="el-icon-arrow-left"></span>-->
<!--          <span class="el-icon-arrow-right" style="margin-right: 0;"></span>-->
        </div>
      </div>
      <div class="DyList">
        <span class="leftBtn el-icon-arrow-left DynamicLeft"></span>
        <span class="rightBtn el-icon-arrow-right DynamicRight"></span>
        <swiper :options="swiperOptionOther">
          <swiper-slide v-for="(item,index) in otherlist" class="item-swiper">
            <div class="item" @click="goInfo(item.sightseeingId)">
              <div class="img scale">
                <img :src="item.hotelCover" alt="">
<!--                <div class="eye el-icon-view">&nbsp;{{ item.visitNum }}</div>-->
              </div>
              <div class="box-1-1">
                <div class="box-1-1-1">
                  <div class="box-1-1-1-1">{{ item.hotelTitle }}</div>
                  <div class="box-1-1-1-2">
                    <span v-if="item.hotelLevel==1">★</span>
                    <span v-if="item.hotelLevel==2">★★</span>
                    <span v-if="item.hotelLevel==3">★★★</span>
                    <span v-if="item.hotelLevel==4">★★★★</span>
                    <span v-if="item.hotelLevel==5">★★★★★</span>
                  </div>
                </div>
                <div class="box-1-1-2">
                  <div v-for="items of item.tags"  class="box-1-1-2-1">{{ items }}</div>
                </div>
                <div class="box-1-1-3">{{item.hotelDesc}}</div>
              </div>
<!--              <div class="con">
                <div class="tit">
                  {{ item.webTitle }}
                </div>
                <div class="time">
                  {{ item.sightseeingCreateTime }}
                </div>
                <div class="conText">
                  {{ item.webDesc }}
                </div>
              </div>-->

            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import {hotelInfo, hotelList, imgURL} from '@/api'
import {getSightseeingList} from "@/api/table";
export default {
  name: "indexInfo",
  components:{
  },
  data(){
    return {
      imgURL,
      tType:'CH',
      url:'',
      data:{},
      swiperOptionJD: {
        // navigation: {
        //   nextEl: '.scenicRight',
        //   prevEl: '.scenicLeft',
        // },
        direction: "horizontal", // 竖向滚动
        // autoHeight: true, // 自适应高度
        slidesPerView: "auto", // 记得这里写 auto 不要默认写1哦
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,

      },
      otherlist:[],
      swiperOptionOther: {
        navigation: {
          nextEl: '.DynamicRight',
          prevEl: '.DynamicLeft',
        },
        direction: "horizontal", // 竖向滚动
        // autoHeight: true, // 自适应高度
        slidesPerView: "auto", // 记得这里写 auto 不要默认写1哦
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // loop: true,

      },
      otherId:0,
      titleStr:'',
    }
  },
  created() {
    this.titleStr = '当前位置：'+ this.$route.matched[0].meta.title +'>' + this.$route.meta.title
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    this.getInfo()
    this.getOtherlist()
  },
  methods:{
    getInfo(){
      hotelInfo(this.$route.query.id,{}).then(res=>{
        console.log(res)
        if(res.code==200){
          this.data=res.data
          this.data.imgList = res.data.hotelCarousel.split(',')
        }
      })
    },
    getOtherlist(){
      hotelList({
        // sightseeingType: this.$route.query.type
      }).then(res=>{
        console.log(res)
        if (res.code==200) {
          res.rows.forEach((item)=>{
            // console.log(item.tags)
            if(item.tags && item.tags.trim().length>0){item.tags = item.tags.split(',')}
            // item.tags=item.tags.split(',')
          })
          this.otherlist=res.rows
          // this.otherlist = []
        }
      })
    },
    goInfo(id){
      this.createlist(id)
      this.otherId = id
      this.getOtherlist()
    },
  }
}
</script>

<style scoped lang="less">
.infoPage{
  width: 100%;
  min-height: 800px;
  //padding: 0 0 0 55px;
  padding-bottom: 120px;
  .topImg{
    width: 100%;
    //height: 800px;
    height: 280px;
    //background: #007B8A ;
    overflow: hidden;
    .imgs{
      width: 100%;
      height: 800px;
    }
    .swiperList{
      height: 100%;
      width: 100%;
      /deep/.swiper-wrapper{
        display: flex;
      }
      .item-swiper{
        height: 800px;
        overflow: hidden;
        min-width: 100%;
      }
    }
  }
  .content{
    width: 1300px;
    //margin:50px auto 0 auto;
    margin: -270px auto 0;
    //height: 800px;
    .navListBox{
      height: 70px;
      //background: #F9F9F9;
      border-radius: 40px 40px 0px 0px;
      font-size: 14px;
      font-family: STSongti-SC-Bold, STSongti-SC;
      font-weight: bold;
      color: #333333;
      line-height: 70px;
      padding-left: 50px;
      span{
        color: #007B8A;
      }
    }
    .infoBox{
      //background: #fff;
      padding: 50px;
      box-sizing: border-box;
    }
    .box{
      display: flex;
      //flex-wrap: nowrap;
      justify-content: space-between;
      .left{
        .info{
          width: 650px;
          border-bottom: 1px solid #DDDDDD;
          padding-bottom: 30px;
          .title{
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            .name{
              font-size: 34px;
              font-family: STSongti-SC-Black, STSongti-SC;
              font-weight: 900;
              color: #333333;
              line-height: 48px;
            }
            .start{
              margin-left: 20px;
              font-size: 20px;
              color: #F2BE00;

            }
          }
          .tags{
            width: 100%;
            margin-top: 20px;
            //display: flex;
            //flex-wrap: nowrap;
            .item{
              margin-right: 10px;
              margin-bottom: 10px;
              width: 74px;
              height: 24px;
              background: rgba(0, 123, 138, 0.1);
              border-radius: 17px;
              font-size: 12px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #007B8A;
              line-height: 24px;
              text-align: center;
              float: left;
            }
          }
          .brief{
            padding-top: 20px;
            clear: both;
            //margin-top: 20px;
            font-size: 14px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #999999;
            line-height: 24px;
          }
        }
        .phone{
          width: 650px;
          padding: 40px 0;
          border-bottom: 1px solid #DDDDDD;
          box-sizing: border-box;
          .title{
            font-size: 20px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 900;
            color: #333333;
            line-height: 28px;
          }
          .icon{
            margin-top: 20px;
            color: #888888;
            span{
              margin-left: 10px;
              font-size: 14px;
              font-family: STHeitiSC-Medium, STHeitiSC;
              font-weight: 500;
              color: #333333;
            }
          }
        }
      }
      .right{
        width: 500px;
        height: 400px;
        background: red;
      }
    }
    .parse{
      margin-top: 30px;
      .title{
        font-size: 20px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #333333;
        line-height: 28px;
      }
      .html{
        margin-top: 22px;
        line-height: 30px;
        img{
          width: 100%;
        }
      }
    }

  }

  .Dynamic{
    //background: #F5F5F5;
    padding: 60px 0 0px 0;
    .title{
      width: 1200px;
      margin: 0px auto 30px;
      display: flex;
      justify-content: space-between;
      align-items: end;
      .text{
        font-size: 34px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #333333;
        span{
          color: #337D8D;
        }
      }
      .lookAll{
        font-size: 20px;
        font-family: STSongti-SC-Regular, STSongti-SC;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
        span{
          margin: 0 20px;
          font-size: 22px;
          font-weight: bold;
          cursor: pointer;
          &:hover{
            color: #337D8D;
          }
        }
      }
    }
    .DyList{
      width: 1200px;
      margin: auto;
      position: relative;
      &:hover{
        .leftBtn,.rightBtn{
          display: block;
          transition:all 1000ms ease;
        }
      }
      .leftBtn,.rightBtn{
        display: none;
        position: absolute;
        top: 50%;
        left: 0;
        width: 50px;
        height: 80px;
        background: rgba(0,0,0,0.5);
        z-index: 5;
        text-align: center;
        line-height: 80px;
        border-radius:0 10px 10px 0;
        transform: translate(0, -50%);
        font-size: 25px;
        color: #007B8A;
        transition:all 1000ms ease;
        cursor: pointer;
      }
      .rightBtn{
        border-radius: 10px 0 0 10px;
        left: auto;
        right: 0;
        transform: translate(0, -50%);
      }
      /deep/.swiper-container{
        overflow: hidden;
      }
      /deep/.swiper-wrapper{
        display: flex;
        //justify-content: space-between;
        .item-swiper{
          width: 380px;
          margin-right: 30px;
          &:nth-last-child(1){
            margin-right: 0;
          }
        }
      }
      .item{
        flex: none;
        width: 380px;
        min-height: 450px;
        height: 100%;
        background: #FFFFFF;
        border-radius: 20px;
        overflow: hidden;
        &:hover{
          .img{
            transition:all 1000ms ease;
            //transform: scale(1);
            transform: scale(1.1);
          }
          .con>.tit{
            color: #337D8D;
          }
          .box-1-1 .box-1-1-1 .box-1-1-1-1{
            color: #337D8D;
          }
        }
        .img{
          width: 100%;
          height: 300px;
          transition:all 500ms ease;
          img{
            width: 100%;
            height: 100%;
          }
          .eye{
            position: absolute;
            top: 16px;
            left: 16px;
            padding: 8px 12px;
            background: #337D8D55;
            border-radius: 5px;
            font-size: 14px;
            font-family: STHeitiSC-Medium, STHeitiSC;
            font-weight: 600;
            color: #ffffff;
          }
        }
        .box-1-1{
          padding: 24px;
          box-sizing: border-box;
          .box-1-1-1{
            display: flex;
            .box-1-1-1-1{
              font-size: 20px;
              font-family: STSongti-SC-Black, STSongti-SC;
              font-weight: 900;
              color: #333333;
            }
            .box-1-1-1-2{
              font-size: 20px;
              color: #F2BE00;
              margin-left: 10px;
            }
          }
          .box-1-1-2{
            display: flex;
            margin: 10px 0;
            .box-1-1-2-1{
              background: rgba(0, 123, 138, 0.1);
              border-radius: 20px;
              font-size: 12px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #007B8A;
              padding: 4px 12px;
              box-sizing: border-box;
              margin-right: 10px;
            }
          }
          .box-1-1-3{
            font-size: 14px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #999999;
            /* 在恰当的断字点进行换行 */
            word-break: break-all;
            /* 超出范围隐藏 */
            overflow: hidden;
            /* 文字超出用省略号 */
            text-overflow: ellipsis;
            /* 盒子模型 */
            display: -webkit-box;
            /* 显示的文本行数 */
            -webkit-line-clamp: 2;
            /* 子元素的垂直排列方式 */
            -webkit-box-orient: vertical;
          }
        }
        /*.con{
          padding: 24px;
          .tit{
            font-size: 20px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 900;
            color: #333333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1; //行数
            overflow: hidden;
          }
          .time{
            font-size: 14px;
            font-family: STHeitiSC-Medium, STHeitiSC;
            font-weight: 500;
            color: #999999;
            margin: 16px 0;
          }
          .conText{
            font-size: 14px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #999999;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; //行数
            overflow: hidden;
          }
        }*/

      }
    }
  }


}
</style>

